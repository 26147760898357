

@media (min-width: 1459px) and (max-width: 1880px) {

  .Overflow-box {
    width: calc(100vw - 305px);
  }
  .Overflow-box-show-menu {
    width: calc(100vw - 130px);
  }

}
@media (min-width: 1459px) and (max-width: 1600px) {

  //.Overflow-box-inKepeper{
  //  width :calc(100vw - 748px) ;
  //}
  .Overflow-box-inKepeper-income {
    width: calc(100vw - 307px);
  }
  .Overflow-box-inKepeper-income-show-menu {
    width: calc(100vw - 130px);
  }

  .Overflow-box-calendar {
    width: calc(100vw - 308px);
  }
  .Overflow-box-calendar-show-menu {
    width: calc(100vw - 130px);
  }
}


@media (min-width: 1600px) and (max-width: 9460px) {
  .Overflow-box-calendar {
    width: calc(100vw - 308px);
  }
  .Overflow-box-calendar-show-menu {
    width: calc(100vw - 130px);
  }
}

@media (min-width: 992px) and (max-width: 1230px) {
  .Overflow-box-inKepeper {
    width: calc(100vw - 286px);
  }
  .Overflow-box-inKepeper-show-menu {
    width: calc(100vw - 112px);
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .Overflow-box-each{
    width :calc(100vw - 288px) ;
  }
  .Overflow-box-each-show-menu{
    width :calc(100vw - 112px) ;
  }
}

@media (min-width: 992px) and (max-width: 1459px) {
  .Overflow-box-show-menu {
    width: calc(100vw - 110px);
  }
  .Overflow-box {
    width: calc(100vw - 288px);
  }
  //.Overflow-box-inKepeper{
  //  width :calc(100vw - 270px) ;
  //}
  .Overflow-box-inKepeper-income {
    width: calc(100vw - 287px);
  }
  .Overflow-box-inKepeper-income-show-menu {
    width: calc(100vw - 110px);
  }

  .Overflow-box-calendar {
    width: calc(100vw - 288px);
  }
  .Overflow-box-calendar-show-menu {
    width: calc(100vw - 115px);
  }
}

@media (min-width: 767px) and (max-width: 992px) {
  .Overflow-box-each {
    width: calc(100vw - 112px);
    padding-right: 0;
  }
  .Overflow-box-each-show-menu{
    width :calc(100vw - 112px) ;
  }
  .Overflow-box-show-menu {
    width: calc(100vw - 110px);
  }
  .Overflow-box {
    width: calc(100vw - 114px);
  }
  .Overflow-box-inKepeper {
    width: calc(100vw - 110px);
  }

  .Overflow-box-inKepeper-income {
    width: calc(100vw - 90px);
  }
  .Overflow-box-inKepeper-income-show-menu {
    width: calc(100vw - 100px);
  }
  .Overflow-box-calendar {
    width: calc(100vw - 110px);
  }
  .Overflow-box-calendar-show-menu {
    width: calc(100vw - 110px);
  }
}

@media (min-width: 0px) and (max-width: 767px) {
  .Overflow-box-each {
    width: calc(100vw - 86px);
    padding-right: 0;
  }
  .Overflow-box-each-show-menu{
    width :calc(100vw - 86px) ;
  }
  .Overflow-box {
    width: calc(100vw - 86px);
  }
  .Overflow-box-show-menu {
    width: calc(100vw - 86px);
  }
  .Overflow-box-inKepeper {
    width: calc(100vw - 86px);
  }
  .Overflow-box-inKepeper-show-menu {
    width: calc(100vw - 100px);
  }
  .Overflow-box-inKepeper-income {
    width: calc(100vw - 86px);
  }
  .Overflow-box-inKepeper-income-show-menu {
    width: calc(100vw - 86px);
  }

  .Overflow-box-calendar {
    width: calc(100vw - 102px);
  }
  .Overflow-box-calendar-show-menu {
    width: calc(100vw - 94px);
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/** MUI SELECT & AUTOCOMPLETE MENU **/
.css-6hp17o-MuiList-root-MuiMenu-list, .css-1kakwwg  {
  padding: 6px!important;
}

.css-1pjvac-MuiButtonBase-root-MuiMenuItem-root, .css-1kakwwg {
  border-radius: 8px!important;
}

//.css-7mne4a-MuiPaper-root-MuiAutocomplete-paper, .css-1kakwwg{
//  padding:5px 6px!important;
//
//  li {
//    border-radius: 8px!important;
//  }
//}
/** MUI SELECT & AUTOCOMPLETE MENU **/

.fc-daygrid-day-top{
  flex-direction: column!important;
}

a {
  text-decoration: none!important;
}

.opacity-50 {
opacity:50%!important
}

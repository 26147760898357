@tailwind base;
@tailwind components;
@tailwind utilities;

.line-clamp-2 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.tooltip-values  {
    @apply space-y-2 flex flex-col
}

.tooltip-value  {
    @apply text-danger
}
